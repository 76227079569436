import { useEffect, useState, useCallback } from 'react';
import { useFrontload } from 'react-frontload';
import { useDispatch, useSelector } from 'react-redux';

import { getValidResponseBody, staticApi } from '../api';
import { setElasticSearchCategories, setMainCats } from '../state/categories/categories';
//todo: introduce TS to project
//type FileLoadingOptions: {
//     onMount: bool,
//     onCallback: bool,
//   }
//@param options = {
//   categoryTree: FileLoadingOptions || false,
//   mainCategories: FileLoadingOptions || false,
// };
export const useCategoryLists = (options) => {
  const { mainCategories: mainCategoriesOption, categoryTree: categoryTreeOption } = options;
  const [loadingCallbackInvoked, setLoadingCallbackInvoked] = useState(false);
  const dispatch = useDispatch();
  const reduxCategories = useSelector(({ categories }) => categories);
  //use selector to check if categories are already loaded

  //@options: loadingMethod - way how fetchCategories will be invoked
  //@options: loadingMethod: "onMount" || "onCallback"
  const fetchCategories = useCallback(
    async (loadingMethod) => {
      const shouldLoadcategoryTree = !reduxCategories?.elasticSearch && categoryTreeOption?.[loadingMethod];
      const shouldLoadMainCategories = !reduxCategories?.main && mainCategoriesOption?.[loadingMethod];
      const [categoriesResponse, mainCategoriesResponse] = await Promise.all([
        shouldLoadcategoryTree ? staticApi.getCategoriesList2() : Promise.resolve(null),
        shouldLoadMainCategories ? staticApi.getShopsMainCategories() : Promise.resolve(null),
      ]);

      const categoryTree = getValidResponseBody(categoriesResponse);
      const mainCategories = getValidResponseBody(mainCategoriesResponse);

      if (categoryTree?.category?.buckets) dispatch(setElasticSearchCategories(categoryTree.category.buckets));
      if (mainCategories) dispatch(setMainCats(mainCategories));

      return { categoryTree, mainCategories: mainCategories?.categories };
    },
    [categoryTreeOption, dispatch, mainCategoriesOption, reduxCategories?.elasticSearch, reduxCategories?.main]
  );

  //Frontload needed to fetch data on SSR
  useFrontload('categories-lists', async () => await fetchCategories('onMount'));

  useEffect(() => {
    if (loadingCallbackInvoked) fetchCategories('onCallback');
    //todo: handle dependencies the proper way. It needs fetchCategories, but it has object dependencies yet
  }, [loadingCallbackInvoked]);

  const loadCategories = () => {
    setLoadingCallbackInvoked(true);
  };

  return {
    mainCategories: reduxCategories?.main,
    categoryTree: reduxCategories?.elasticSearch,
    loadCategories,
  };
};
